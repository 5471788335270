import React, { Component, useState, useEffect } from 'react';
import "./mulitilevellist.css";
import { LiSearchBox, LiCheckBox, LiTooltip3, LiSwitch } from 'liseccomponents';
import * as conversionLib from "lisecutilityfunctions/lib/commonfunction";
// import lightFilterIcon from './images/liselectlistgrid/LightTheme/filter.svg';
// import darkFilterIcon from './images/liselectlistgrid/DarkTheme/filter.svg';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';


const baseUrl = window.location;

const LiMultiLevelList = (props) => {
    const theme = conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "LightTheme";
    const headerText = props.headerText ? props.headerText : ""
    const [mainListData, setmainListData] = useState(props.listData);
    const [onToggleChange, setonToggleChange] = useState(false);
    const mainListDataForSearch = props.listData;
    const featureIDFE = (conversionLib.getParameterByName('featureid', baseUrl.href) !== null && conversionLib.getParameterByName('featureid', baseUrl.href) !== '') ? conversionLib.getParameterByName('featureid', baseUrl.href) : 0;


    React.useEffect(() => {
        onToggleChangeSelectedFeactures(onToggleChange)
    }, [onToggleChange, props.listData]);

    const liselectListOnChange = (selectedIdx, event) => {

        const element = document.getElementById(event.target.id);
        if (!element)
            return;

        let tempMainList = [...mainListData];
        const recursiveIdSearch = (eachHeaderRow) => {

            if (eachHeaderRow.id === selectedIdx) {
                eachHeaderRow.checked = document.getElementById(event.target.id).checked;
                return;
            }
            else if (eachHeaderRow.sublevel && eachHeaderRow.sublevel.length !== 0) {
                eachHeaderRow.sublevel.map((eachSub, ids) => {
                    recursiveIdSearch(eachSub);
                    return;
                })
            }
        }
        tempMainList.length !== 0 && tempMainList.map((eachHeaderRow, idx) => {
            recursiveIdSearch(eachHeaderRow)
        })
        setmainListData(tempMainList);

        if (props.onChangeListData)
            props.onChangeListData(tempMainList)
        //document.getElementById(event.target.id).checked
    }

    const sublevelSearch = (searchValue, sublevelRows) => {
        if (!sublevelRows || sublevelRows.length === 0)
            return false;
        let isContain = false
        sublevelRows.forEach((eachRow) => {
            if (eachRow.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                isContain = true;
            }
            else if (eachRow.sublevel && eachRow.sublevel.length !== 0) {
                let returnVal = sublevelSearch(searchValue, eachRow.sublevel);
                isContain = returnVal;
            }
        })
        return isContain;

    }

    const searchVal = (searchValue) => {
        if (searchValue == "") {
            setmainListData(props.listData)
            return;
        }
        const filteredData = mainListDataForSearch.filter((eachItem) => {
            if (eachItem?.description?.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1 || sublevelSearch(searchValue, eachItem.sublevel)) {
                return eachItem;
            }
        })
        setmainListData(filteredData)
    }

    const dynamicRecursiveChildNodes = (eachSubRow, idx) => {
        return (
            <div key={eachSubRow.id} className='secondleveleachrow' style={{ paddingLeft: '4%', }}>
                <LiCheckBox
                    LiCheckBoxDisable={eachSubRow.id == featureIDFE}
                    LiCheckBoxChecked={eachSubRow.checked}
                    // LiCheckBoxChecked={this.state.alldataChecked}
                    LiCheckBoxID={idx + "_" + eachSubRow.id}
                    LiCheckBoxPlaceholder="LiCheckBox 1"
                    LiCheckBoxClassName="Header_Checkbox"
                    LiCheckOnChange={liselectListOnChange.bind(this, eachSubRow.id)}
                />
                {eachSubRow.muTextId ? <LiMultiterm textId={JSON.stringify(eachSubRow.muTextId)} textVal={eachSubRow.description} /> : eachSubRow.description !== '' ? eachSubRow.description : '-'}
                {/* {eachSubRow.description !== '' ? eachSubRow.description : '-'} */}
                {
                    eachSubRow.sublevel && eachSubRow.sublevel.length !== 0 && eachSubRow.sublevel.map((eachNext, idn) => {
                        return dynamicRecursiveChildNodes(eachNext, idx + '_' + idn)
                    })
                }

            </div>
        )
    }

    const renderMuLevlList = (eachRow, id) => {
        return (

            <div key={id} id={'firstleveleachrow_' + eachRow.id.toString()} className="firstleveleachrow">
                <LiCheckBox
                    LiCheckBoxDisable={eachRow.id === featureIDFE}
                    LiCheckBoxChecked={eachRow.checked}
                    // LiCheckBoxChecked={this.state.alldataChecked}
                    LiCheckBoxID={id + "_" + eachRow.id.toString()}
                    LiCheckBoxPlaceholder="LiCheckBox 1"
                    LiCheckBoxClassName="Header_Checkbox"
                    LiCheckOnChange={liselectListOnChange.bind(this, eachRow.id)}
                />
                {eachRow.muTextId ? <LiMultiterm textId={JSON.stringify(eachRow.muTextId)} textVal={eachRow.description} /> : eachRow.description !== '' ? eachRow.description : '-'}
                {/* {eachRow.description !== '' ? eachRow.description : '-'} */}
                {eachRow.sublevel && eachRow.sublevel.length !== 0 && eachRow.sublevel.map((eachSubRow, ids) => {
                    return dynamicRecursiveChildNodes(eachSubRow, id)

                })}
            </div>
        )
    }

    /**
     * On Toggle Change,
     * IF true, Show all the selected features
     * ELSE show all the features
     * @param {Boolean} isToggleOn
     */
    const onToggleChangeSelectedFeactures = (isToggleOn) => {
        const flatten = (data) => {
            return data.reduce((r, { sublevel, ...rest }) => {
                r.push(rest);
                if (sublevel) r.push(...flatten(sublevel));
                return r;
            }, []);
        };

        const findAncestryById = (targetId) =>
            ancestry(({ id }) => id == targetId);

        const ancestry = (pred) => (obj) => {
            for (let nodes of walk(obj)) {
                if (pred(nodes.at(-1))) {
                    return nodes;
                }
            }
            return [];
        };

        function* walk(xs, path = []) {
            for (let x of xs) {
                const newPath = path.concat(x);
                yield newPath;
                yield* walk(x.sublevel || [], newPath);
            }
        }

        const showOnlySelectedChildWithNode = (data) => {
            let showWithParentNode = false
            let flattenedArray = flatten(data);
            const array = flattenedArray?.filter((item) => item?.checked === true);
            if (!showWithParentNode) {
                setmainListData(array);
            } else if (showWithParentNode) {
                let finalArray = [];
                array.forEach((item) => {
                    let a = findAncestryById(item?.id)(data);
                    finalArray.push(a[0]);
                });
                setmainListData(finalArray);
            }
        };

        if (isToggleOn) {
            showOnlySelectedChildWithNode(JSON.parse(JSON.stringify(props.listData)))
        }
        if (!isToggleOn)
            setmainListData(props.listData);
    };



    /**
     * On Toggle Change,
     * Set !Previous value
     */
    const onToggleChageFunc = () => {
        setonToggleChange(onToggleChange => !onToggleChange);
    }


    // console.log('rerender', mainListData, featureIDFE)
    return (
        <div id={props.id} className={theme + " LiMuLevList_MainContainer"}>

            <div className='multievellistheadermain'>

                <div className="LiGridList_HeaderBlock">
                    <span className="LiGridListTitle">{headerText}</span>
                </div>


                <div className="LiSearchMainContainer">
                    <div className="LiSearchBarDiv">
                        <LiSearchBox
                            liSearchBoxID={props.id + '_searchBox'}
                            isSearchIcon={false}
                            onChangeText={searchVal.bind(this)}
                            userDefinedClass="ClassForGridBox"
                        />
                    </div>
                    {/* 
                    <img
                        src={theme === 'LightTheme' ? lightFilterIcon : darkFilterIcon}
                        width="20"
                        className="LigridList_Filter"
                    /> */}
                    {props.isToggleenabled && <span className="Licheckbox_features">

                        <LiTooltip3 position="bottom-right" content={<LiMultiterm textId="99032584" textVal="Show only selected items" />}>
                            <div>
                                <LiSwitch switchName="allfeatures" switchId="allfeatures" switchDisabled={!props.isToggleenabled} switchVal="allfeatures" switchOnChangeHandler={onToggleChageFunc} switchChecked={onToggleChange} />
                            </div>
                        </LiTooltip3>

                    </span>}
                </div>
            </div>
            <div className='multievellistbodymain'>
                {
                    mainListData.length !== 0 ? mainListData.map((eachRow, id) => {
                        return renderMuLevlList(eachRow, id)
                    })
                        :
                        <div className="LiGridList_HeaderBlock">
                            <span className="LiGridListTitle">
                                <LiMultiterm textId="99011122" textVal="No data available!" />
                            </span>
                        </div>
                }
            </div>

        </div>
    )

}




export default LiMultiLevelList;



